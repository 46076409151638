// @flow

import classNames from 'classnames';
import React from 'react';
import type { Node } from 'react';
import { Row, Col } from '@riseart/antd-provider';
import { Link, Heading, Paragraph } from '@riseart/common';
import { Section, Wrapper } from '@riseart/layout';
import {
  ArrowPointCircleIcon,
  PersonCircleIcon,
  CheckCircleIcon,
  LockCircleIcon,
} from '@riseart/icons';

import {
  trustSectionCls,
  trustSectionItemCls,
  trustSectionItemIconCls,
  trustSectionFooterCls,
} from 'shared_components/cms/modules/info/TrustSection.less';

type Props = {
  className?: string,
  disableHeadingTags?: boolean,
  title: string,
  footer: {
    text: string,
    link: {
      external: boolean,
      target: string,
      href: String,
      text?: string,
    },
  },
  items: Array<{
    title: string,
    description: string,
    icon: string,
    link: {
      external: boolean,
      target: string,
      href: String,
      text?: string,
    },
  }>,
};

/**
 * CmsModuleTrustSection
 *
 * @param {Props} props
 * @returns {Node}
 */
export const CmsModuleTrustSection = ({
  title,
  footer,
  items,
  className,
  disableHeadingTags = false,
}: Props): Node => {
  const totalItems = items && items.length;

  if (!items || !totalItems) {
    return null;
  }

  const ICON_COMPONENTS = {
    pointArrow: ArrowPointCircleIcon,
    check: CheckCircleIcon,
    lock: LockCircleIcon,
    person: PersonCircleIcon,
  };

  return (
    <Section className={classNames(trustSectionCls, className)} background="gray">
      <Wrapper
        sizes={{
          xs: 'wide',
          sm: 'narrow',
          md: 'wide',
          lg: 'narrow',
          xl: 'narrow',
        }}
      >
        {title ? (
          <Heading tag={disableHeadingTags ? 'span' : 'h2'} level="3" align="center">
            {title}
          </Heading>
        ) : null}
        <Row
          gutter={[
            { xs: 32, md: 64 },
            { xs: 32, md: 64 },
          ]}
          justify="center"
        >
          {items.map(({ title, description, icon, link }) => {
            const Icon = ICON_COMPONENTS[icon];

            return (
              <Col key={title} xs={24} sm={12} md={totalItems > 3 ? 6 : 8}>
                <div className={trustSectionItemCls}>
                  {Icon && (
                    <div className={trustSectionItemIconCls}>
                      <Icon />
                    </div>
                  )}
                  <Heading tag={disableHeadingTags ? 'span' : 'h3'} level="4">
                    {title}
                  </Heading>
                  <Paragraph>{description}</Paragraph>
                  <div>
                    <Link
                      href={link.href}
                      title={link.text}
                      external={link.external}
                      {...(link.target ? { target: link.target } : null)}
                    >
                      {link.text}
                    </Link>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        {footer && (footer.text || footer.link) ? (
          <div className={trustSectionFooterCls}>
            {footer.text ? <span>{footer.text}</span> : null}
            {footer.link ? (
              <React.Fragment>
                {' '}
                <Link
                  href={footer.link.href}
                  title={footer.link.text}
                  external={footer.link.external}
                  {...(footer.link.target ? { target: footer.link.target } : null)}
                >
                  {footer.link.text}
                </Link>
              </React.Fragment>
            ) : null}
          </div>
        ) : null}
      </Wrapper>
    </Section>
  );
};
